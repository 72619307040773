// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$dark-text: #262626;
$light-text: #ffffff;

// WatchGuard Palettes
// grays
$wg-gray-palette: mat.define-palette(
  (
    50: #ffffff, // snow
    100: #fafafa, // ghost
    200: #ebebeb, // platinum
    300: #d4d4d4, // silver
    400: #b5b5b5, // slate
    500: #999999, // watchguard-gray
    600: #7c7c7c, // tarmac
    700: #606060, // gravel
    800: #434343, // space
    900: #262626, // abyss
    'A100': #000000, // black
    'A200': #f2f2f2, // ash
    contrast:
      (
        50: $dark-text,
        100: $dark-text,
        200: $dark-text,
        300: $dark-text,
        400: $light-text,
        500: $light-text,
        600: $light-text,
        700: $light-text,
        800: $light-text,
        900: $light-text,
      ),
  ),
  500
);

$wg-blue-palette: mat.define-palette(
  (
    50: #edf9fc, // ice
    100: #b6e8f2,
    200: #7ed2e2, // sky
    300: #62c8df,
    400: #57c5d9, // lagoon
    500: #48b2cd,
    600: #43a3ba,
    700: #359db0, // robin-egg
    800: #367b88, // pacific
    900: #2c585d,
    contrast: (
      50: $dark-text,
      100: $dark-text,
      200: $dark-text,
      300: $dark-text,
      400: $light-text,
      500: $light-text,
      600: $light-text,
      700: $light-text,
      800: $light-text,
      900: $light-text,
    ),
  ),
  800
);

$wg-red-palette-obj: (
  50: #fff0f0, //blush
  100: #ffc7b8,
  200: #ffa28a,
  300: #ff795b,
  400: #ff5436,
  500: #ff200c,
  600: #ff1507,
  700: #ff0000, // watchguard-red
  800: #f10000,
  900: #cf0000, // ruby
  contrast:
    (
      50: $dark-text,
      100: $dark-text,
      200: $dark-text,
      300: $dark-text,
      400: $light-text,
      500: $light-text,
      600: $light-text,
      700: $light-text,
      800: $light-text,
      900: $light-text,
    ),
);

$wg-watchguard-red-palette: mat.define-palette($wg-red-palette-obj, 700);
$wg-ruby-palette: mat.define-palette($wg-red-palette-obj, 900);

$wg-theme: mat.define-light-theme(
  (
    color: (
      primary: $wg-blue-palette,
      accent: $wg-ruby-palette,
      warn: $wg-watchguard-red-palette,
    ),
    typography: mat.define-typography-config(),
  )
);

@include mat.all-component-themes($wg-theme);

@import '@angular/material/theming';

// Global Styles

*{
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
}

a {
  text-decoration: none;

  &,
  &:link,
  &:visited,
  &:focus,
  &:hover,
  &:active {
    color: mat.get-color-from-palette($wg-blue-palette, 700);
  }

  &.mat-tab-link {
    &,
    &:link,
    &:visited,
    &:focus,
    &:hover,
    &:active {
      color: mat.get-color-from-palette($wg-blue-palette, '300-contrast');
    }
  }
}

.mat-mdc-card-title {
  font-size: 16px !important;
}

.card-header-wg {
  background-color: #ae0000;
  color: #ffffff;
  padding: 10px 16px !important;
  display: flex;
  justify-content: space-between;
}

.grayscaleblur {
  filter: blur(3px);
}

.admin-view-dialog {
  .mat-mdc-dialog-surface {
    padding: 20px !important;
  }
}

.hidden-property {
  display: none !important;
}